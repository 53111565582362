#discussProjectDiv.initial-state,
#requestConsultDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  transition: all 0.5s ease-in-out;
}

#discussProjectDiv.initial-state #chatInput {
  position: relative;
  top: 0;
  left: 0;
  transform: none;
}

#discussProjectDiv.first-message-sent {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80vh;
  animation: fadeIn 1s forwards;
}

#discussProjectDiv.first-message-sent #chatInput {
  position: static;
  transform: none;
}

#chatWindow.hidden,
#projectSection.hidden {
  visibility: hidden;
}

#chatWindow.fade-in,
#projectSection.fade-in {
  visibility: visible;
  opacity: 0;
  animation: fadeIn 1s forwards;
}

#chatInput.fade-out {
  animation: fadeOut 500ms forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#projectDescriptionDiv h1,
#projectDescriptionDiv h2,
#projectDescriptionDiv h3,
#projectDescriptionDiv h4,
#projectDescriptionDiv h5,
#projectDescriptionDiv h6 {
  color: #1e3a8a; /* Tailwind sky-900 */
  /* margin-bottom: 0.5rem; */
}

#projectDescriptionDiv p {
  color: #374151; /* Tailwind gray-700 */
  /* margin-bottom: 0.5rem; */
}

#projectDescriptionDiv ul li::marker {
  color: #1e3a8a; /* Tailwind sky-900 */
}

#projectDescriptionDiv ol li::marker {
  color: #1e3a8a; /* Tailwind sky-900 */
}

#projectDescriptionDiv em {
  font-style: italic;
  color: #1e3a8a; /* Tailwind sky-900 */
}

#projectDescriptionDiv strong {
  font-weight: bold;
  color: #1e3a8a; /* Tailwind sky-900 */
}

#projectDescriptionDiv a {
  color: #0ea5e9; /* Tailwind sky-500 */
  text-decoration: underline;
}

#projectDescriptionDiv blockquote {
  border-left: 4px solid #1e3a8a; /* Tailwind sky-900 */
  padding-left: 1rem;
  color: #374151; /* Tailwind gray-700 */
  margin-bottom: 1rem;
}
